import { useMemo } from 'react';
import { reduce, get, includes, isEqual, find, head } from '../../lib/nodash';
import { INTERIOR_STANDARD } from '../../lib/productTypes';

const useInitialProductValuesForPaintCollections = (
  paintCollections,
  selectedProducts,
  initialProductIds = []
) => {
  const initialProductValues = useMemo(() => {
    return (
      selectedProducts ||
      reduce(
        (mem, collection) => {
          mem[collection.handle] =
            collection.handle === 'primer'
              ? get('shopifyId', head(get('products', collection)))
              : get(
                  'shopifyId',
                  find((x) => {
                    return (
                      includes(get('shopifyId', x), initialProductIds) ||
                      isEqual(INTERIOR_STANDARD, get('productType', x))
                    );
                  }, get('products', collection))
                );
          return mem;
        },
        {},
        paintCollections
      )
    );
  }, [paintCollections, selectedProducts, initialProductIds]);
  return initialProductValues;
};

export default useInitialProductValuesForPaintCollections;
