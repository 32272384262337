import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Stack, Box } from 'grommet';
import { Checkmark } from 'grommet-icons';
import { m } from 'framer-motion';
import styled from 'styled-components';

import PaintCollectionCard from '../Paint/PaintCollectionCard';

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 55px 55px 0 0;
  border-color: #000000 transparent transparent transparent;
`;

const CalcBrowsePaintCollectionsItem = ({
  paintCollection,
  onSelect,
  isSelected,
  allowProductSelection = true,
  srcSetSizes = '(max-width: 768px) 80vw, 15vw',
  ...rest
}) => {
  return (
    <Stack anchor="top-left" fill style={{ zIndex: 0 }}>
      <PaintCollectionCard
        collection={paintCollection}
        overlay={false}
        productField={
          allowProductSelection ? `products.${paintCollection.handle}` : null
        }
        routeType="paint"
        fill={true}
        onClick={() => onSelect(paintCollection)}
        focusIndicator={false}
        headingLevel={5}
        style={{ transition: 'border 0.2s' }}
        srcSetSizes={srcSetSizes}
        border={{
          side: 'all',
          size: 'small',
          color: isSelected ? 'black' : 'transparent',
        }}
        {...rest}
      />
      <m.div
        animate={{
          opacity: isSelected ? 1 : 0,
          scale: isSelected ? 1 : 0,
        }}
        transition={{ duration: 0.2 }}
        initial={{
          opacity: 0,
          scale: 0,
        }}
      >
        <Stack>
          <Triangle />
          <Box pad="8px">
            <m.div
              animate={{
                rotate: isSelected ? '0deg' : '25deg',
              }}
              transition={{
                type: 'spring',
                duration: 1,
                bounce: 0.7,
              }}
            >
              <Checkmark color="white" size="18px" />
            </m.div>
          </Box>
        </Stack>
      </m.div>
    </Stack>
  );
};

CalcBrowsePaintCollectionsItem.propTypes = {
  paintCollection: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  allowProductSelection: PropTypes.bool,
  srcSetSizes: PropTypes.string,
};

export default memo(CalcBrowsePaintCollectionsItem);
