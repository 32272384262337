import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { get, intersection } from '../../lib/nodash';
import { Formik } from 'formik';
import { Box, Stack, Button, Grid, Form, Heading } from 'grommet';
import { LazyMotion, m } from 'framer-motion';
import StickyBox from 'react-sticky-box';
import styled from 'styled-components';

import DynamicIcon from '../Icons/DynamicIcon';
import ColorFilterPanel from '../Color/ColorFilterPanel';
import Loading from '../Loading';
import makeSelectFilteredPaintCollections from '../../state/product/makeSelectFilteredPaintCollections';
import CalcBrowsePaintCollectionsItem from '../Calculator/CalcBrowsePaintCollectionsItem';
import { toggleSelectProduct } from '../../state/product/productSlice';
import useInitialProductValuesForPaintCollections from './useInitialProductValuesForPaintCollections';
import useCollectionItemZoomSize from '../useCollectionItemZoomSize';
import useMobile from '../useMobile';

const loadFeatures = () =>
  import('../../lib/framer/motionMaxFeatures.js').then((res) => res.default);

const FilterContainer = styled(Box)`
  height: auto;
  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (min-width: ${p.theme.global.breakpoints.small.value}px)`} {
    width: 20vw;
    max-width: 230px;
    min-width: 180px;
    height: 100%;
    border-right: ${(p) => p.theme.global.borderSize.small} solid;
    border-top: ${(p) => p.theme.global.borderSize.small} solid;
  }
`;

const ChoosePaintProducts = ({
  filterGroups,
  onContinue,
  continueLabel = 'Continue',
  onCancel,
  cancelLabel = 'Cancel',
  title,
  cartLoading = false,
  paintCollectionArrangement = [],
  paintCollections = [],
  colorWheel,
}) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const selectFilteredPaint = useMemo(makeSelectFilteredPaintCollections, []);
  const selectedProductIds = useSelector(
    (state) => state.product.selectedProducts
  );
  const selectedCollections = useMemo(
    () =>
      selectedProductIds.length > 0
        ? paintCollections.filter((collection) => {
            return (
              intersection(
                collection.products.map((p) => p.shopifyId),
                selectedProductIds
              ).length > 0
            );
          })
        : [],
    [selectedProductIds, paintCollections]
  );

  const filteredPaintCollections = useSelector((state) =>
    selectFilteredPaint(state, {
      paintCollectionArrangement,
      paintCollections,
      colorWheel,
    })
  );

  const initialProductValues =
    useInitialProductValuesForPaintCollections(paintCollections);

  const { colCount, templateSize, srcSetSizes } = useCollectionItemZoomSize();

  return (
    <LazyMotion strict features={loadFeatures}>
      <Formik
        initialValues={{
          products: initialProductValues,
          skipToCheckout: false,
        }}
        onSubmit={() => {
          const selectedProducts = selectedCollections.reduce((mem, c) => {
            const matchingProds = c.products.filter(
              (p) => selectedProductIds.indexOf(p.shopifyId) > -1
            );
            mem = mem.concat(matchingProds);
            return mem;
          }, []);

          onContinue(selectedProducts);
        }}
      >
        {({ values, handleSubmit }) => {
          const handleSelection = (collection) => {
            dispatch(
              toggleSelectProduct(
                get(collection.handle, get('products', values))
              )
            );
          };
          return (
            <Form onSubmit={handleSubmit} style={{ height: '100%' }}>
              <Stack anchor="bottom-right" fill>
                <Box fill className="outer">
                  <Box
                    overflow="auto"
                    fill
                    className="choose-paint-product-scroller"
                  >
                    {title && (
                      <Box
                        pad="large"
                        flex={false}
                        className="choose-paint-product-heading"
                      >
                        <Heading level={3}>{title}</Heading>
                      </Box>
                    )}
                    <div>
                      <Box
                        fill
                        align="start"
                        className="inner"
                        direction="row-responsive"
                      >
                        <FilterContainer>
                          <StickyBox offsetTop={20} offsetBottom={20}>
                            <ColorFilterPanel
                              searchOptions={paintCollections}
                              filterGroups={filterGroups}
                              fill
                            />
                          </StickyBox>
                        </FilterContainer>

                        <Box flex={true} pad={{ vertical: 'medium' }}>
                          <Grid
                            columns={{ count: colCount, size: 'auto' }}
                            gap="small"
                            pad={{
                              horizontal: 'medium',
                              bottom: '200px',
                              top: 'medium',
                            }}
                          >
                            {filteredPaintCollections.map((collection) => {
                              const isSelected =
                                intersection(
                                  collection.products.map((p) => p.shopifyId),
                                  selectedProductIds
                                ).length > 0;

                              return (
                                <m.div
                                  key={collection.id}
                                  layout="position"
                                  transition={{ duration: 0.4 }}
                                  animate={{ opacity: [0, 1] }}
                                  initial={{ opacity: 0 }}
                                >
                                  <CalcBrowsePaintCollectionsItem
                                    key={collection.id}
                                    paintCollection={collection}
                                    isSelected={isSelected}
                                    onSelect={handleSelection}
                                    allowProductSelection={false}
                                    srcSetSizes={srcSetSizes}
                                    hideSampleButton={true}
                                    hideTags={
                                      templateSize === 'small' ||
                                      templateSize === 'xsmall'
                                    }
                                    hideTitle={templateSize === 'xsmall'}
                                    size={templateSize}
                                    imageContainerProps={
                                      templateSize === 'xsmall'
                                        ? {
                                            pad: 0,
                                          }
                                        : {}
                                    }
                                    containerProps={
                                      templateSize === 'xsmall'
                                        ? {
                                            pad: 0,
                                          }
                                        : {}
                                    }
                                  />
                                </m.div>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Box>
                    </div>
                  </Box>
                </Box>
                <Box pad="medium" direction="row-responsive" gap="small">
                  {onCancel && !isMobile && (
                    <Button
                      label={cancelLabel}
                      secondary
                      icon={<DynamicIcon type="close" />}
                      reverse
                      onClick={onCancel}
                    />
                  )}
                  <Button
                    disabled={selectedProductIds.length === 0}
                    label={continueLabel}
                    primary
                    reverse
                    icon={
                      cartLoading ? <Loading /> : <DynamicIcon type="plus" />
                    }
                    type="submit"
                  />
                </Box>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </LazyMotion>
  );
};

ChoosePaintProducts.propTypes = {
  filterGroups: PropTypes.array,
  onContinue: PropTypes.func.isRequired,
  continueLabel: PropTypes.string,
  cartLoading: PropTypes.bool,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  paintCollectionArrangement: PropTypes.array,
  paintCollections: PropTypes.array.isRequired,
  colorWheel: PropTypes.object.isRequired,
};

export default memo(ChoosePaintProducts);
