import React, { useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';
import { get, find, head } from '../../lib/nodash';
import { useStaticQuery, graphql } from 'gatsby';
import { useStoryblokState } from '../../lib/storyblok';

import ChoosePaintProducts from './ChoosePaintProducts';
import DynamicIcon from '../Icons/DynamicIcon';
import { isVariantHalfGal } from '../../lib/product';
import useAddToCart from '../Cart/useAddToCart';
import encodeGid from '../../lib/encodeGid';
import deNodify from '../../lib/deNodify';
import { setActiveModal } from '../../state/ui/uiSlice';

const ChooseTouchUpKitPaint = () => {
  const dispatch = useDispatch();
  const { storyblokEntry, pail, brush, arrangement, paint, colorWheel } =
    useStaticQuery(graphql`
      query TouchUpKitPaintFilterSet {
        colorWheel: strapiColorWheel {
          Colors {
            color {
              name
              shopify_product_ids
            }
          }
        }
        storyblokEntry(full_slug: { eq: "filters/default-paint-filters" }) {
          content
          id
          slug
          uuid
        }
        pail: shopifyProductVariant(title: { regex: "/pail/i" }) {
          shopifyId
        }
        brush: shopifyProductVariant(title: { regex: "/brush/i" }) {
          shopifyId
        }
        arrangement: storyblokEntry(
          full_slug: { eq: "paint/default-paint-arrangement" }
        ) {
          full_slug
          content
          id
          slug
          uuid
          internalId
        }
        paint: allShopifyCollection(
          filter: {
            metafields: {
              elemMatch: {
                key: { eq: "type" }
                namespace: { eq: "collection" }
                value: { eq: "Paint" }
              }
            }
          }
          sort: {
            order: [ASC, ASC]
            fields: [
              products___variants___product___handle
              products___variants___id
            ]
          }
        ) {
          edges {
            node {
              id
              shopifyId
              title
              handle
              metafields {
                type
                key
                value
                description
                namespace
              }
              products {
                id
                shopifyId
                title
                productType
                variants {
                  id
                  shopifyId
                  price
                  title
                  availableForSale
                  inventoryPolicy
                }
              }
            }
          }
        }
      }
    `);
  const story = useStoryblokState(storyblokEntry);
  const { addToCart, loading } = useAddToCart();
  const filterGroups = get('content.filterGroups', story) || [];
  const arrangementStory = useStoryblokState(arrangement);
  const paintCollectionArrangement = (
    get('content.paintColors', arrangementStory) || []
  ).map((x) => get('paintColor.item.shopifyId', x));
  const paintCollections = deNodify(paint);

  const handleCancel = useCallback(() => {
    dispatch(setActiveModal(null));
  }, [dispatch]);
  const handleChoice = useCallback(
    async (selectedProducts) => {
      const halfGallon = find(
        isVariantHalfGal,
        get('variants', head(selectedProducts))
      );
      await addToCart([
        {
          variantId: encodeGid(brush.shopifyId),
          quantity: 1,
        },
        {
          variantId: encodeGid(pail.shopifyId),
          quantity: 1,
        },
        {
          variantId: encodeGid(halfGallon.shopifyId),
          quantity: 1,
        },
      ]);
      dispatch(setActiveModal(null));
    },
    [encodeGid, brush, pail]
  );
  return (
    <ChoosePaintProducts
      onContinue={handleChoice}
      continueLabel="Add Touch Up Kit to Cart"
      continueIcon={<DynamicIcon type="plus" />}
      filterGroups={filterGroups}
      cartLoading={loading}
      title="Choose your paint to touch up"
      cancelLabel="Cancel"
      onCancel={handleCancel}
      paintCollectionArrangement={paintCollectionArrangement}
      paintCollections={paintCollections}
      colorWheel={colorWheel}
    />
  );
};

export default memo(ChooseTouchUpKitPaint);
